import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

const style = {
	grid: {
		position: 'relative',
		width: '100%',
		minHeight: '1px',
		paddingRight: '15px',
		paddingLeft: '15px',
		flexBasis: 'auto',
	},
};

const GridItem = ({ classes, children, className, ...rest }) => (
	<Grid item {...rest} className={classes.grid + ' ' + className}>
		{children}
	</Grid>
);

GridItem.defaultProps = {
	className: '',
};

GridItem.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.node,
	className: PropTypes.string,
};

export default withStyles(style)(GridItem);
