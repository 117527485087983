/* eslint-disable react/prop-types */
import React from 'react';

export const Alignable = ({ className }) => (
	<svg
		className={className}
		width='128'
		height='128'
		viewBox='0 0 128 128'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M66.2889 50.261L58.7889 32.261C63.5889 27.461 70.7889 25.9277 73.7889 25.761C76.9889 25.361 82.4555 26.5944 84.7889 27.261C91.1889 30.461 94.1222 33.261 94.7889 34.261C99.5889 39.061 103.122 45.261 104.289 47.761C111.489 62.561 113.622 68.9277 113.789 70.261C114.189 64.661 113.956 58.9277 113.789 56.761C110.189 37.161 96.9555 24.9277 90.7889 21.261C69.5889 9.26104 52.9555 12.9277 47.2889 16.261C52.4889 39.061 61.1222 54.4277 64.7889 59.261C68.3889 65.261 72.9555 65.761 74.7889 65.261C78.3889 65.261 81.6222 60.9277 82.7889 58.761C86.7889 64.761 89.4555 72.9277 90.2889 76.261C80.6889 84.661 69.2889 83.0943 64.7889 81.261C46.7889 74.061 34.9555 40.9277 31.2889 25.261C27.6889 26.861 21.4555 35.9277 18.7889 40.261C10.7889 55.461 12.7889 71.9277 14.7889 78.261C17.9889 90.261 25.1222 97.9277 28.2888 100.261L39.7888 65.261C45.7888 75.261 50.9555 80.0943 52.7888 81.261L43.2888 110.261C50.4888 114.661 62.6222 115.094 67.7888 114.761C80.1888 113.561 89.2888 108.261 92.2888 105.761C98.6888 100.961 102.622 96.761 103.789 95.261C99.5605 81.8106 90.0016 54.6394 85.2889 50.261C84.5517 47.3101 80.3402 43.0795 74.7889 43.261C72.7427 43.5603 68.8399 45.824 66.2889 50.261Z'
			fill='#3C4858'
		/>
	</svg>
);
export const Nextdoor = ({ className }) => (
	<svg
		className={className}
		width='128'
		height='102'
		viewBox='0 0 128 102'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M127.96 39.3562L63.9995 0.188232L42.0195 13.6482V0.188232H21.9995V25.8962L0.0195312 39.3562L10.4795 56.4402L21.9995 49.4202V101.816H106V49.4202L117.5 56.4402L127.96 39.3562V39.3562Z'
			fill='#3C4858'
		/>
	</svg>
);
