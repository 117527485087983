import { title } from 'global-material';

export const productStyle = {
	section: {
		padding: '70px 0',
		textAlign: 'center',
	},
	title: {
		...title,
		marginBottom: '1rem',
		marginTop: '30px',
		minHeight: '32px',
		textDecoration: 'none',
	},
	description: {
		color: '#999',
	},
};

export const aboutStyle = {
	section: {
		padding: '70px 0',
		textAlign: 'center',
	},
	header: {
		color: '#6c6c6c',
		fontWeight: 'bold',
	},
	paragraph: {
		color: '#6c6c6c',
		fontSize: '16px',
		lineHeight: '2em',
		paddingRight: '36px',
		textAlign: 'left',
	},
	single: {
		color: '#6c6c6c',
		fontSize: '16px',
		lineHeight: '2em',
		paddingRight: '36px',
		textAlign: 'left',
	},
	centered: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	imgStyle: {
		width: '100%',
		'@media (min-width: 1750px)': {
			height: '66%',
		},
	}
};
