import React, { useEffect, useRef, useState } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import { parallaxStyle } from './Parallax.css';

const Parallax = ({
	about,
	children,
	classes,
	className,
	filter,
	image,
	small,
	style,
}) => {
	const initialWindowScrollTop =
		typeof window !== 'undefined' && window.pageYOffset / 3;

	const [transform, setTransform] = useState(
		`translate3d(0,${initialWindowScrollTop || 0}px,0)`
	);
	const ref = useRef(null);
	const parallaxClasses = classNames({
		[classes.parallax]: true,
		[classes.filter]: filter,
		[classes.about]: about,
		[classes.small]: small,
		[className]: className !== undefined,
	});
	const resetTransform = () => {
		const windowScrollTop =
			typeof window !== 'undefined' && window.pageYOffset / 3;
		setTransform(`translate3d(0,${windowScrollTop || 0}px,0)`);
	};
	useEffect(() => {
		const windowScrollTop =
			typeof window !== 'undefined' && window.pageYOffset / 3;
		setTransform(`translate3d(0,${windowScrollTop || 0}px,0)`);
		window.addEventListener('scroll', resetTransform);
		return () => {
			typeof window !== 'undefined' &&
				window.removeEventListener('scroll', resetTransform);
		};
	}, []);
	return (
		<div
			className={parallaxClasses}
			style={{
				...style,
				backgroundImage: `url(${image})`,
				transform,
			}}
			ref={ref}>
			{children}
		</div>
	);
};

Parallax.propTypes = {
	about: PropTypes.bool,
	children: PropTypes.node,
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	filter: PropTypes.bool,
	image: PropTypes.string,
	small: PropTypes.bool,
	style: PropTypes.string,
};

export default withStyles(parallaxStyle)(Parallax);
