/*eslint-disable*/
import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
import { List, ListItem, withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

// React icons
import { FaInstagram, FaLinkedin } from 'react-icons/fa';

import Button from 'components/CustomButtons/Button.js';
import { Alignable, Nextdoor } from 'components/Icon/index.js';
import footerStyle from './Footer.css.js';

function Footer({ ...props }) {
	const { classes, whiteFont } = props;
	const footerClasses = classNames({
		[classes.footer]: true,
		[classes.footerWhiteFont]: whiteFont,
	});
	const aClasses = classNames({
		[classes.a]: true,
		[classes.footerWhiteFont]: whiteFont,
	});
	return (
		<footer className={footerClasses}>
			<div className={classes.container}>
				<div className={classes.right}>
					<List className={classes.list}>
						<ListItem className={classes.inlineBlock}>
							<Tooltip
								id='instagram'
								title='Follow us on Instagram'
								placement={
									typeof window !== 'undefined' &&
									window.innerWidth > 959
										? 'top'
										: 'left'
								}
								classes={{ tooltip: classes.tooltip }}>
								<Button
									href='https://instagram.com/arcinsurancerva'
									target='_blank'
									color='transparent'
									className={classes.navLink}>
									<FaInstagram className={classes.icon} />
								</Button>
							</Tooltip>
						</ListItem>
						<ListItem className={classes.inlineBlock}>
							<Tooltip
								id='linkedin'
								title='Connect with us on LinkedIn'
								placement={
									typeof window !== 'undefined' &&
									window.innerWidth > 959
										? 'top'
										: 'left'
								}
								classes={{ tooltip: classes.tooltip }}>
								<Button
									color='transparent'
									href='https://www.linkedin.com/in/arcinsurancerva'
									target='_blank'
									className={classes.navLink}>
									<FaLinkedin className={classes.icon} />
								</Button>
							</Tooltip>
						</ListItem>
						<ListItem className={classes.inlineBlock}>
							<Tooltip
								id='alignable'
								title='Align with us on Alignable'
								placement={
									typeof window !== 'undefined' &&
									window.innerWidth > 959
										? 'top'
										: 'left'
								}
								classes={{ tooltip: classes.tooltip }}>
								<Button
									color='transparent'
									href='https://www.alignable.com/henrico-va/arc-insurance-llc'
									target='_blank'
									className={classes.navLink}>
									<Alignable className={classes.icon} />
								</Button>
							</Tooltip>
						</ListItem>
						<ListItem className={classes.inlineBlock}>
							<Tooltip
								id='nextdoor'
								title='Come see us on Nextdoor'
								placement={
									typeof window !== 'undefined' &&
									window.innerWidth > 959
										? 'top'
										: 'left'
								}
								classes={{ tooltip: classes.tooltip }}>
								<Button
									color='transparent'
									href='https://nextdoor.com/pages/arc-insurance-agency-richmond-va/recommend/'
									target='_blank'
									className={classes.navLink}>
									<Nextdoor className={classes.icon} />
								</Button>
							</Tooltip>
						</ListItem>
						<br />
						<small>
							<a href='mailto:aricnsurancerva@gmail.com?subject=Looking for a Quote'>
								Email the office
							</a>{' '}
							|{' '}
							<a href='https://goo.gl/maps/saqESLNWzzXeWTCn6'>
								3605 Mayland Ct Henrico, VA 23233
							</a>
						</small>
						<br />
						<small>
							&copy; {1900 + new Date().getYear()} made by{' '}
							<a
								href='https://www.johnblackwell.dev'
								className={aClasses}
								target='_blank'>
								JDB Dev
							</a>
						</small>
					</List>
				</div>
			</div>
		</footer>
	);
}

Footer.propTypes = {
	classes: PropTypes.object.isRequired,
	whiteFont: PropTypes.bool,
};

export default withStyles(footerStyle)(Footer);
